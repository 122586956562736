html {
  background-color: black;
  font-size: 62.5%;
  color: white;
  height: 100%;
  background-image: url("background.gif");
  background-repeat: no-repeat;
  background-size: cover;
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}